import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Box } from "./../../components/elements";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { CardLayout } from "./../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor, { buttonList } from "suneditor-react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button, Anchor, Icon } from "./../../components/elements";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "./../../components";
import Modal from "react-bootstrap/Modal";
import { MultiSelect } from "react-multi-select-component";
import setLog from "../../lib";
import { AllCheckerCheckbox, Checkbox, CheckboxGroup } from "@createnl/grouped-checkboxes";

export default function ProductList() {
  const navigate = useNavigate();
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  ////////////////////
  const [ids, setIds] = useState([]);
  const [activityLevel, setActivityLevel] = useState(1);
  const [showSwal, setShowSwal] = useState(false);
  const [uploadData, setUploadData] = useState(false);
  const [Saving, setSaving] = useState(false);
  const [masterDestinations, setMasterDestination] = useState("");
  const [subMasterDestinations, setSubMasterDestination] = useState([]);

  const [masterExperience, setMasterExperience] = useState("");
  const [subMasterExperience, setSubMasterExperience] = useState([]);
  const [expereance, setExperience] = useState("");
  const [subExperience, setSubExperience] = useState([]);

  const [title, setTitle] = useState("");
  const [destination, setDestination] = useState("");
  const [subDestination, setSubDestination] = useState([]);
  const [description, setDescription] = useState("");
  const [hotels, setHotels] = useState("");
  const [meals, setMeals] = useState("");
  const [activities, setActivities] = useState("");
  const [otherServices, setOtherServices] = useState("");
  const [notIncluded, setNotIncluded] = useState("");
  const [note, setNote] = useState("");
  const [note2, setNote2] = useState("");

  const [ship, setShip] = useState("");
  const [id, setId] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [selectedTab, setSelectedTab] = useState("overView");
  const [data, setData] = useState({});
  const [mode, setMode] = useState("add");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [itineraryId, setItineraryId] = useState("");
  const [itineraryDay, setItineraryDay] = useState("");
  const [itineraryTitle, setItineraryTitle] = useState("");
  const [itineraryDesc, setItineraryDesc] = useState("");
  const [itineraryMeals, setItineraryMeals] = useState("");
  const [itineraryAccommodation, setItineraryAccommodation] = useState("");
  const [itineraryNote, setItineraryNote] = useState("");
  const [itinerarySlug, setItinerarySlug] = useState("");
  const [coverImage, setCoverImage] = useState([]);

  const [slug, setSlug] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDesc, setSeoDesc] = useState("");

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [data2, setData2] = useState([]);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);

  const [activityLevelList, setActivityLevelList] = useState([]);

  ////////////////////

  useEffect(() => {
    fetchActivityLevel();
    fetchDestination();
    fetchExperience();
  }, []);

  useEffect(() => {
    fetchProduct();
    fetchItinerary();
  }, [masterDestinations]);

  async function fetchActivityLevel() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/activity/all", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setActivityLevelList(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function fetchExperience() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/experiences/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterExperience(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  function getSubExperience(id) {
    if (masterExperience) var data = masterExperience.find((e) => e._id == id);
    if (data && data.childs) {
      var arr = [];
      for (let i = 0; i < data.childs.length; i++) {
        arr.push({
          label: data.childs[i].title,
          value: data.childs[i]._id,
        });
      }
      setSubMasterExperience(arr);
    }
    setExperience(id);
  }

  async function fetchItinerary() {
    const options = {
      headers: { authorization: token },
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    if (urlParams && urlParams.get("id")) {
      const Response = await axios.get(process.env.REACT_APP_API_URL + "app/itinerary/list?id=" + id, options);
      if (Response && Response.data.success) {
        if (Response.data.result) {
          setData(Response.data.result);
        }
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    }
  }

  async function fetchProduct() {
    const options = {
      headers: { authorization: token },
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("id")) {
      const id = urlParams.get("id");

      if (urlParams && urlParams.get("id")) {
        setDisabled(false);
        const Response = await axios.get(process.env.REACT_APP_API_URL + "app/products/detail/" + id, options);
        if (Response && Response.data.success) {
          if (Response.data.result) {
            getSubDestinations(Response.data.result.parentDestination._id);
            getSubExperience(Response.data.result.parentExpereance);
            setTitle(Response.data.result.title);
            setDestination(Response.data.result.parentDestination._id);
            setDescription(Response.data.result.desc);
            setHotels(Response.data.result.hotels);
            setMeals(Response.data.result.meals);
            setActivities(Response.data.result.activities);
            setOtherServices(Response.data.result.otherServices);
            setNotIncluded(Response.data.result.notIncluded);
            setNote(Response.data.result.note);
            setNote2(Response.data.result.note);

            setActivityLevel(Response.data.result.activityLevelField);
            setCoverImage(Response.data.result.image);
            setSlug(Response.data.result.slug);
            setShip(Response.data.result.ship);

            setSeoTitle(Response.data.result.seoTitle);
            setSeoDesc(Response.data.result.seoDesc);

            if (Response.data.result.subDestination) {
              var arr = [];
              for (let i = 0; i < Response.data.result.subDestination.length; i++) {
                arr.push({
                  label: Response.data.result.subDestination[i].title,
                  value: Response.data.result.subDestination[i]._id,
                });
              }
              setSubDestination(arr);
            }

            if (Response.data.result.subExpereance) {
              var arr = [];
              for (let i = 0; i < Response.data.result.subExpereance.length; i++) {
                arr.push({
                  label: Response.data.result.subExpereance[i].title,
                  value: Response.data.result.subExpereance[i]._id,
                });
              }
              setSubExperience(arr);
            }

            // setSubDestination([{ label: Response.data.result.subDestination.title, value: Response.data.result.subDestination._id }]);
            // setSubExperience([{ label: Response.data.result.subExpereance.title, value: Response.data.result.subExpereance._id }]);
          }
        } else {
          setSaving(false);
          toast.error(Response.data.message);
        }
      }
    }
  }

  async function fetchDestination() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/destination/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterDestination(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  function getSubDestinations(id) {
    setDestination(id);
    if (masterDestinations) var data = masterDestinations.find((e) => e._id == id);
    if (data && data.childs) {
      var arr = [];
      for (let i = 0; i < data.childs.length; i++) {
        arr.push({
          label: data.childs[i].title,
          value: data.childs[i]._id,
        });
      }
      setSubMasterDestination(arr);
    }
  }

  async function handleOverview() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    if (!destination && destination == "") {
      toast.error("Select destination");
      return;
    }

    if (!subDestination && subDestination == "") {
      toast.error("Select sub destination");
      return;
    }

    if (!title && title == "") {
      toast.error("Enter title");
      return;
    }

    if (!description && description == "") {
      toast.error("Select description");
      return;
    }

    if (!hotels && hotels == "") {
      toast.error("Select hotels");
      return;
    }

    if (!meals && meals == "") {
      toast.error("Enter meals");
      return;
    }

    if (!activities && activities == "") {
      toast.error("Enter activities");
      return;
    }

    if (!otherServices && otherServices == "") {
      toast.error("Enter other services");
      return;
    }

    if (!notIncluded && notIncluded == "") {
      toast.error("Enter not included");
      return;
    }

    // if (!note && note == "") {
    //   toast.error("Enter note");
    //   return;
    // }

    if (id) {
      if (!expereance && expereance == "") {
        toast.error("Select experience");
        return;
      }

      if (!subExperience && subExperience == "") {
        toast.error("Select sub experience");
        return;
      }
    }
    if (disabled) {
      handleOverviewConfirm();
    } else {
      handleShow2();
    }
  }

  async function handleOverviewConfirm(force) {
    setSaving(true);
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    var insertData = [];

    insertData["activityLevel"] = activityLevel;
    insertData["parentDestination"] = destination;
    insertData["subDestination"] = subDestination.map((option) => option.value);
    insertData["parentExpereance"] = expereance && expereance != "" ? expereance : null;
    insertData["subExpereance"] = subExperience.map((option) => option.value);
    insertData["title"] = title;
    insertData["desc"] = description;
    insertData["hotels"] = hotels;
    insertData["meals"] = meals;
    insertData["activities"] = activities;
    insertData["otherServices"] = otherServices;
    insertData["notIncluded"] = notIncluded;
    insertData["note"] = note2;
    insertData["ship"] = ship;
    insertData["force"] = force;
    insertData["slug"] = slug;
    insertData["seoTitle"] = seoTitle;

    if (disabled) {
      const Response = await axios.post(process.env.REACT_APP_API_URL + "app/products/add", qs.stringify(insertData), options);
      if (Response.data.status == "success") {
        var note = force == "" ? "Force: No - " + title : "Force: Yes - " + title;
        setLog({
          adminUser: userData._id,
          module: "Master product",
          action: "Add",
          note: note,
        });

        setSaving(false);
        setId(Response.data.productId);
        setDisabled(false);
        setSelectedTab("itinerary");
        window.history.replaceState("", "", "/manage-products?id=" + Response.data.productId);
        toast.success(Response.data.message);
        fetchProduct();
        setIds([]);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        setIds([]);
      }
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");

      const values = ids
        .filter((item) => item.checked)
        .map((item) => item.value)
        .join(",");

      insertData["subdomains"] = values;
      const Response = await axios.post(process.env.REACT_APP_API_URL + "app/products/update/" + id, qs.stringify(insertData), options);
      if (Response.data.status == "success") {
        var note = force.trim() == "" ? "Force: No - " + title : "Force: Yes - " + title;
        setLog({
          adminUser: userData._id,
          module: "Master product",
          action: "Update",
          note: note,
        });
        setId([]);
        setSaving(false);
        toast.success(Response.data.message);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    }
  }

  async function handleItinerary() {
    if (!itineraryDay && itineraryDay == "") {
      toast.error("Enter itinerary day");
      return;
    }

    if (!itineraryTitle && itineraryTitle == "") {
      toast.error("Enter itinerary title");
      return;
    }

    if (!itineraryDesc && itineraryDesc == "") {
      toast.error("Enter itinerary descption");
      return;
    }

    if (mode == "add") {
      handleItineraryConfirm();
    } else {
      handleShow3();
    }
  }

  async function handleItineraryConfirm(force) {
    setSaving(true);
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const uploadFormData = new FormData();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    uploadFormData.append("product", id);
    uploadFormData.append("day", itineraryDay);
    uploadFormData.append("title", itineraryTitle);
    uploadFormData.append("desc", itineraryDesc);
    uploadFormData.append("meals", itineraryMeals);
    uploadFormData.append("accommodation", itineraryAccommodation);
    uploadFormData.append("upload", uploadData);
    uploadFormData.append("supplemental", selectedTab == "supplemental" ? true : false);
    uploadFormData.append("note", itineraryNote);
    uploadFormData.append("force", force);
    uploadFormData.append("slug", itinerarySlug);

    if (mode == "add") {
      const Response = await axios.post(process.env.REACT_APP_API_URL + "app/itinerary/add", uploadFormData, options);
      if (Response.data.status == "success") {
        var note = force == "" ? "Force: No - " + itineraryTitle : "Force: Yes - " + itineraryTitle;
        setLog({
          adminUser: userData._id,
          module: "Itinerary: " + title,
          action: "Add",
          note: note,
        });

        setSaving(false);
        toast.success(Response.data.message);
        fetchItinerary();
        setShow(false);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    } else {
      const values = ids
        .filter((item) => item.checked)
        .map((item) => item.value)
        .join(",");

      uploadFormData.append("subdomains", values);
      const Response = await axios.post(process.env.REACT_APP_API_URL + "app/itinerary/update/" + itineraryId, uploadFormData, options);
      if (Response.data.status == "success") {
        setSaving(false);
        toast.success(Response.data.message);
        fetchItinerary();
        setShow(false);

        var note = force.trim() == "" ? "Force: No - " + itineraryTitle : "Force: Yes - " + itineraryTitle;
        setLog({
          adminUser: userData._id,
          module: "Itinerary: " + title,
          action: "Update",
          note: note,
        });
        setIds([]);
      } else {
        setIds([]);
        setSaving(false);
        toast.error(Response.data.message);
      }
    }
  }

  // On File Select Load It To Memory
  function fileHandler(e) {
    const file = e.currentTarget.files[0];
    setUploadData(file);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/itinerary/delete/" + itineraryId, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchItinerary();
          setShowSwal(false);

          setLog({
            adminUser: userData._id,
            module: "Itinerary: " + title,
            action: "Delete",
            note: itineraryTitle,
          });
          setItineraryTitle("");
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  async function handleCoverImage() {
    setSaving(true);
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const uploadFormData = new FormData();
    uploadFormData.append("upload", uploadData);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/products/update-cover/" + id, uploadFormData, options);
    if (Response.data.status == "success") {
      setSaving(false);
      setCoverImage(Response.data.result);
      toast.success(Response.data.message);

      setLog({
        adminUser: userData._id,
        module: "Master product image",
        action: "Add",
        note: title,
      });
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function removeImage(key, item) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/products/delete-image/" + id,
      qs.stringify({
        index: key,
        image: item,
      }),
      options
    );
    if (Response.data.status == "success") {
      setSaving(false);
      setCoverImage(Response.data.result);
      toast.success(Response.data.message);

      setLog({
        adminUser: userData._id,
        module: "Master product image",
        action: "Delete",
        note: title,
      });
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  function linkedDomain() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    setShow4(true);
    const options = {
      headers: { authorization: token },
    };
    axios.get(process.env.REACT_APP_API_URL + "app/products/domain?productId=" + id, options).then((response) => {
      if (response.data.success) {
        setShow4(true);
        setData2(response.data.result);
      } else {
        toast(response.data.message);
      }
    });
  }

  function linkedDomain2() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    const options = {
      headers: { authorization: token },
    };
    axios.get(process.env.REACT_APP_API_URL + "app/products/domain?productId=" + id, options).then((response) => {
      if (response.data.success) {
        setShow5(true);
        setData2(response.data.result);
      } else {
        toast(response.data.message);
      }
    });
  }

  const onCheckboxChange = (checkboxes) => {
    setIds(checkboxes);
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Manage product"}>
              <li className="mc-breadcrumb-item">
                <Anchor icon={"chevron_left"} text={"Back"} href={"/products"} className="mc-btn primary" />
              </li>

              <li className="mc-breadcrumb-item">
                <Button icon={"save"} className="mc-btn primary w-100" disable={!Saving} onClick={() => handleOverview()}>
                  {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
                </Button>
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Tabs defaultActiveKey={selectedTab} className="mb-3">
                  <Tab eventKey="overView" onClick={() => setSelectedTab("overView")} title="Over View">
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Destination</label>
                            <select className="form-control" onChange={(e) => getSubDestinations(e.target.value)} value={destination}>
                              <option value=""> Select </option>
                              {masterDestinations?.length > 0 &&
                                masterDestinations.map((item, key) => (
                                  <option value={item._id} key={key}>
                                    {item.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Sub Destination</label>
                            <MultiSelect options={subMasterDestinations} value={subDestination} onChange={setSubDestination} labelledBy="Select" />
                          </div>
                        </div>
                      </div>

                      <div className="form-group mt-3">
                        <label>Title</label>
                        <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                      </div>

                      {slug && slug != "" && (
                        <div className="form-group mt-3">
                          <label>Slug</label>
                          <input type="text" className="form-control" value={slug} onChange={(e) => setSlug(e.target.value)} />
                        </div>
                      )}

                      <div className="form-group mt-3">
                        <label>Description</label>
                        <SunEditor
                          height={200}
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setDescription}
                          setContents={description}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Hotels</label>
                        <SunEditor
                          height={200}
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setHotels}
                          setContents={hotels}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Meals</label>
                        <SunEditor
                          height={200}
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setMeals}
                          setContents={meals}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Activities</label>{" "}
                        <SunEditor
                          height={200}
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setActivities}
                          setContents={activities}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Other Services</label>

                        <SunEditor
                          height={200}
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setOtherServices}
                          setContents={otherServices}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Not Included</label>{" "}
                        <SunEditor
                          height={200}
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setNotIncluded}
                          setContents={notIncluded}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Ship</label>
                        <SunEditor
                          height={200}
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setShip}
                          setContents={ship}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Note</label>
                        <SunEditor
                          height={200}
                          setOptions={{
                            buttonList: buttonList.complex,
                          }}
                          onChange={setNote2}
                          setContents={note}
                        />
                      </div>

                      <button type="button" className="btn btn-primary mt-5" onClick={() => handleOverview()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save & Proceed</>}
                      </button>
                    </div>
                  </Tab>
                  <Tab eventKey="itinerary" onClick={() => setSelectedTab("itinerary")} title="Itinerary" disabled={disabled}>
                    <div className="row">
                      <div className="col-md-9"></div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={() => {
                            setMode("add");
                            setItineraryAccommodation("");
                            setItineraryMeals("");
                            setItineraryDesc("");
                            setItineraryTitle("");
                            setItineraryDay("");
                            setItineraryId("");
                            setItineraryNote("");
                            setItinerarySlug("");
                            setShow(true);
                          }}
                        >
                          Add itinerary
                        </button>
                      </div>
                    </div>

                    <div className="">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <>
                            {!item.supplemental && (
                              <div className="row mt-3 pb-5 border-bottom" key={key}>
                                <div className="col-md-1">
                                  <div className="text-center">
                                    Day <br /> <span className="h3">{item.day}</span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div style={{ fontSize: 23 }}>{item.title}</div>
                                  <div className="mt-3 mb-3">{item.desc.replace(/(<p>|<\/p>)/g, "").substring(0, 300)} ...</div>
                                  {item.meals.trim() != "" && (
                                    <>
                                      <div>
                                        <strong>Meals:</strong> {item.meals}
                                      </div>
                                    </>
                                  )}

                                  {item.accommodation.trim() != "" && (
                                    <>
                                      <div>
                                        <strong>Accommodation:</strong> {item.accommodation}
                                      </div>
                                    </>
                                  )}
                                </div>

                                <div className="col-md-3">
                                  <img src={process.env.REACT_APP_IMG_URL + "itinerary/cropped/" + item.image} className="img-fluid rounded border" style={{ height: "200px" }} />
                                </div>

                                <div className="col-md-2">
                                  <div className="mc-table-action text-center">
                                    <button
                                      title="Edit"
                                      className="material-icons edit  "
                                      onClick={() => {
                                        setMode("edit");
                                        setItineraryAccommodation(item.accommodation);
                                        setItineraryMeals(item.meals);
                                        setItineraryDesc(item.desc);
                                        setItineraryTitle(item.title);
                                        setItinerarySlug(item.slug);
                                        setItineraryDay(item.day);
                                        setItineraryId(item._id);
                                        setShow(true);
                                      }}
                                    >
                                      <Icon>edit</Icon>
                                    </button>
                                    <button
                                      title="Edit"
                                      className="material-icons delete "
                                      onClick={() => {
                                        setItineraryId(item._id);
                                        setShowSwal(true);
                                        setItineraryTitle(item.title);
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </Tab>

                  <Tab eventKey="supplemental" onClick={() => setSelectedTab("supplemental")} title="Supplemental" disabled={disabled}>
                    <div className="row">
                      <div className="col-md-9"></div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={() => {
                            setMode("add");
                            setItineraryAccommodation("");
                            setItineraryMeals("");
                            setItineraryDesc("");
                            setItineraryTitle("");
                            setItinerarySlug("");
                            setItineraryDay("");
                            setItineraryId("");
                            setItineraryNote("");
                            setShow(true);
                          }}
                        >
                          Add itinerary
                        </button>
                      </div>
                    </div>

                    <div className="">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <>
                            {item.supplemental && (
                              <div className="row mt-3 pb-5 border-bottom" key={key}>
                                <div className="col-md-1">
                                  <div className="text-center">
                                    Day <br /> <span className="h3">{item.day}</span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div style={{ fontSize: 23 }}>{item.title}</div>
                                  <div className="mt-3 mb-3">{item.desc.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 300)} ...</div>
                                  <div>
                                    <strong>Meals:</strong> {item.meals}
                                  </div>
                                  <div>
                                    <strong>Accommodation:</strong> {item.accommodation}
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <img src={process.env.REACT_APP_IMG_URL + "itinerary/cropped/" + item.image} className="img-fluid rounded border" style={{ height: "200px" }} />
                                </div>

                                <div className="col-md-2">
                                  <div className="mc-table-action text-center">
                                    <button
                                      title="Edit"
                                      className="material-icons edit  "
                                      onClick={() => {
                                        setMode("edit");
                                        setItineraryAccommodation(item.accommodation);
                                        setItineraryMeals(item.meals);
                                        setItineraryDesc(item.desc);
                                        setItineraryTitle(item.title);
                                        setItinerarySlug(item.slug);
                                        setItineraryDay(item.day);
                                        setItineraryId(item._id);
                                        setItineraryNote(item.note);
                                        setShow(true);
                                      }}
                                    >
                                      <Icon>edit</Icon>
                                    </button>
                                    <button
                                      title="Edit"
                                      className="material-icons delete "
                                      onClick={() => {
                                        setItineraryId(item._id);
                                        setShowSwal(true);
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </Tab>

                  <Tab eventKey="activityLevel" onClick={() => setSelectedTab("activityLevel")} title="Activity Level" disabled={disabled}>
                    <div>
                      <div className="form-group">
                        <label>Activity Level</label>
                        <select className="form-control" onChange={(e) => setActivityLevel(e.target.value)} value={activityLevel}>
                          {activityLevelList?.length > 0 &&
                            activityLevelList.map((item, key) => (
                              <option value={item._id} key={key}>
                                {item.level} - {item.adminTitle}
                              </option>
                            ))}
                        </select>
                      </div>

                      <button type="button" className="btn btn-primary mt-5" onClick={() => handleOverview()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save </>}
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="experenace" onClick={() => setSelectedTab("experenace")} title="Experience" disabled={disabled}>
                    <div>
                      <div className="form-group">
                        <label>Experience</label>
                        <select className="form-control" onChange={(e) => getSubExperience(e.target.value)} value={expereance}>
                          <option value=""> Select </option>
                          {masterExperience?.length > 0 &&
                            masterExperience.map((item, key) => (
                              <option value={item._id} key={key}>
                                {item.title}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="form-group mt-2">
                        <label>Sub Experience</label>

                        <MultiSelect options={subMasterExperience} value={subExperience} onChange={setSubExperience} labelledBy="Select" />

                        {/* <select className="form-control" onChange={(e) => setSubExperience(e.target.value)} value={subExperience}>
                          <option value=""> Select </option>
                          {subMasterExperience?.length > 0 &&
                            subMasterExperience.map((item, key) => (
                              <option value={item._id} key={key}>
                                {item.title}
                              </option>
                            ))}
                        </select> */}
                      </div>

                      <button type="button" className="btn btn-primary mt-5" onClick={() => handleOverview()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save </>}
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="coverImage" onClick={() => setSelectedTab("coverImage")} title="Cover Image" disabled={disabled}>
                    <div>
                      <LabelField label="Image (600x350)" type="file" fieldSize="w-100 h-md" accept="image/*" onChange={(e) => fileHandler(e)} />

                      <button type="button" className="btn btn-primary mt-5" onClick={() => handleCoverImage()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save </>}
                      </button>

                      {coverImage.length > 0 && (
                        <div className="row">
                          {coverImage.map((item, key) => (
                            <div key={key} className="col-md-2">
                              <div style={{ position: "relative" }}>
                                <span
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 5,
                                    zIndex: 99999,
                                    fontSize: 25,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeImage(key, item)}
                                >
                                  &times;
                                </span>
                                <img src={process.env.REACT_APP_IMG_URL + "product/optimized/" + item} className="img-fluid mt-3" style={{ height: "100px" }} />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="SEO" onClick={() => setSelectedTab("SEO")} title="SEO" disabled={disabled}>
                    <div>
                      <div className="form-group mt-3">
                        <label>
                          SEO Title <small>(50-60 char recomended)</small>
                        </label>
                        <input type="text" className="form-control" value={seoTitle} onChange={(e) => setSeoTitle(e.target.value)} />
                      </div>

                      <div className="form-group mt-3">
                        <label>
                          SEO Description <small>(150-160 char recomended)</small>
                        </label>
                        <textarea className="form-control" value={seoDesc} onChange={(e) => setSeoDesc(e.target.value)}></textarea>
                      </div>

                      <button type="button" className="btn btn-primary mt-5" onClick={() => handleOverview()}>
                        {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save & Proceed</>}
                      </button>
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} Itinerary</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <LabelField label="Day" type="number" fieldSize="w-100 h-md" value={itineraryDay} onChange={(e) => setItineraryDay(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Title" type="text" fieldSize="w-100 h-md" value={itineraryTitle} onChange={(e) => setItineraryTitle(e.target.value)} />
            </Col>

            {mode != "add" && (
              <Col xl={12}>
                <LabelField label="Slug" type="text" fieldSize="w-100 h-md" value={itinerarySlug} onChange={(e) => setItinerarySlug(e.target.value)} />
              </Col>
            )}

            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Description</label>
                <SunEditor
                  height={200}
                  setOptions={{
                    buttonList: buttonList.complex,
                  }}
                  onChange={setItineraryDesc}
                  setContents={itineraryDesc}
                />

                {/* <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={itineraryDesc} onChange={(e) => setItineraryDesc(e.target.value)}></textarea> */}
              </div>
            </Col>

            <Col xl={12}>
              <LabelField label="Meal" type="text" fieldSize="w-100 h-md" value={itineraryMeals} onChange={(e) => setItineraryMeals(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Accommodation" type="text" fieldSize="w-100 h-md" value={itineraryAccommodation} onChange={(e) => setItineraryAccommodation(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Image (600x350)" type="file" fieldSize="w-100 h-md" accept="image/*" onChange={(e) => fileHandler(e)} />
            </Col>

            {selectedTab == "supplemental" && (
              <Col xl={12}>
                <div className="mc-label-field-group label-col">
                  <label className="mc-label-field-title"> Itinerary Note</label>
                  <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={itineraryNote} onChange={(e) => setItineraryNote(e.target.value)}></textarea>
                </div>
              </Col>
            )}

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleItinerary()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This listing will be permanently deleted from the product and assigned domain.
        </SweetAlert>
      )}

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: 400 }}>
          <div className="row">
            <div className="col-md-6">
              <Button
                className="btn btn-primary w-100"
                onClick={() => {
                  handleClose2();
                  handleOverviewConfirm("");
                }}
              >
                Update
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                className="btn btn-danger w-100"
                onClick={() => {
                  linkedDomain();
                }}
              >
                Update Selected
              </Button>
            </div>
          </div>
          <p className="text-center mt-2 text-danger" style={{ fontSize: 12 }}>
            Update Selected Will Replace Content Of <br /> Selected Subdomain's Products
          </p>
        </Modal.Body>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: 400 }}>
          <div className="row">
            <div className="col-md-6">
              <Button
                className="btn btn-primary w-100"
                onClick={() => {
                  handleClose3();
                  handleItineraryConfirm("");
                }}
              >
                Update
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                className="btn btn-danger w-100"
                onClick={() => {
                  linkedDomain2();
                }}
              >
                Update Selected
              </Button>
            </div>
          </div>
          <p className="text-center mt-2 text-danger" style={{ fontSize: 12 }}>
            Update Selected Will Replace Content Of <br />
            Selected Subdomain's Product's Itinerary
          </p>
        </Modal.Body>
      </Modal>

      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>Product domain relation</Modal.Header>
        <Modal.Body style={{ width: 500 }}>
          <Box className="mc-table-responsive">
            <CheckboxGroup onChange={onCheckboxChange}>
              <Table className="mc-table">
                <Thead className="mc-table-head primary">
                  <Tr>
                    <th>
                      <AllCheckerCheckbox />
                    </th>
                    <Th>Domain</Th>
                  </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                  {data2?.length > 0 &&
                    data2.map((item, key) => (
                      <>
                        <Tr key={key}>
                          <td>
                            <Checkbox value={item.subdomain._id} />
                          </td>
                          <Td>
                            <strong>
                              {item.subdomain && (
                                <>
                                  <a href={"https://" + item.subdomain.retreatURL + ".iamfit4travel.com"} target="_blank">
                                    <FaExternalLinkSquareAlt /> {item.subdomain.retreatURL + ".iamfit4travel.com"}
                                  </a>
                                </>
                              )}
                            </strong>
                          </Td>
                        </Tr>
                      </>
                    ))}
                </Tbody>
              </Table>
            </CheckboxGroup>
            <hr />
            <Button
              className="btn btn-primary w-100"
              type="button"
              disable={!Saving}
              onClick={() => {
                handleClose2();
                handleClose4();
                handleOverviewConfirm("yes");
              }}
            >
              {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
            </Button>
          </Box>
        </Modal.Body>
      </Modal>

      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>Product domain relation</Modal.Header>
        <Modal.Body style={{ width: 500 }}>
          <Box className="mc-table-responsive">
            <CheckboxGroup onChange={onCheckboxChange}>
              <Table className="mc-table">
                <Thead className="mc-table-head primary">
                  <Tr>
                    <th>
                      <AllCheckerCheckbox />
                    </th>
                    <Th>Domain</Th>
                  </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                  {data2?.length > 0 &&
                    data2.map((item, key) => (
                      <>
                        <Tr key={key}>
                          <td>
                            <Checkbox value={item.subdomain._id} />
                          </td>
                          <Td>
                            <strong>
                              {item.subdomain && (
                                <>
                                  <a href={"https://" + item.subdomain.retreatURL + ".iamfit4travel.com"} target="_blank">
                                    <FaExternalLinkSquareAlt /> {item.subdomain.retreatURL + ".iamfit4travel.com"}
                                  </a>
                                </>
                              )}
                            </strong>
                          </Td>
                        </Tr>
                      </>
                    ))}
                </Tbody>
              </Table>
            </CheckboxGroup>
            <hr />
            <Button
              className="btn btn-primary w-100"
              type="button"
              disable={!Saving}
              onClick={() => {
                handleClose3();
                handleClose5();
                handleItineraryConfirm("yes");
              }}
            >
              {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
            </Button>
          </Box>
        </Modal.Body>
      </Modal>
    </PageLayout>
  );
}
